import React from "react";
import { ImPhoneHangUp } from "react-icons/im";
import { IoCall } from "react-icons/io5";

const IncomingNotification = ({
  incomingNumber,
  handleAnswerCall,
  handleRejectCall,
}: {
  incomingNumber: string;
  handleAnswerCall: () => void;
  handleRejectCall: () => void;
}) => {
  return (
    <div className="flex items-center justify-between shadow-2xl p-3 gap-3 rounded-lg absolute z-50 bg-white w-full">
      <div>
        <div className="flex flex-col justify-center items-center text-md">
          <div>Incoming Call...</div>
          <div>{incomingNumber}</div>
        </div>
      </div>
      <div className="flex justify-center items-center gap-3">
        <div
          className={`cursor-pointer m-[8px] mx-auto w-[50px] h-[50px] text-xl flex items-center bg-green-500 justify-center rounded-full`}
          onClick={handleAnswerCall}
        >
          <IoCall className="text-white" />
        </div>
        <div
          className="cursor-pointer m-[8px] mx-auto w-[50px] h-[50px] text-xl flex items-center bg-red-500 justify-center rounded-full"
          onClick={handleRejectCall}
        >
          <ImPhoneHangUp className="text-white" />
        </div>
      </div>
    </div>
  );
};

export default IncomingNotification;
