import { Route, Routes } from "react-router-dom";
import HomePage from "pages/Home";
import PhonePage from "pages/Phone";
import NotFoundPage from "pages/NotFound";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        {
          process.env.REACT_APP_ENVIRONMENT === "development" &&
          <Route path="/test" element={<HomePage />} />
        }
        <Route path="/phone" element={<PhonePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;
