const NotFoundPage = () => {

  return (
    <>
      Welcome to Triage Logic
    </>
  );
};

export default NotFoundPage;
