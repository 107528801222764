export const API_BASE_URL: string =
  (process.env.REACT_APP_BACKEND_URL as string) + "/api/v1";

export const HOLD_AUDIO_URL =
  "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/11/file_example_MP3_700KB.mp3";

export const dialPadConfig: {
  digit: string;
  subset: string;
}[] = [
  { digit: "1", subset: "" },
  { digit: "2", subset: "ABC" },
  { digit: "3", subset: "DEF" },
  { digit: "4", subset: "GHI" },
  { digit: "5", subset: "JKL" },
  { digit: "6", subset: "MNO" },
  { digit: "7", subset: "PQRS" },
  { digit: "8", subset: "TUV" },
  { digit: "9", subset: "WXYZ" },
  { digit: "*", subset: "" },
  { digit: "0", subset: "+" },
  { digit: "#", subset: "" },
];
