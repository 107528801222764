import React, { ReactElement } from "react";
import { PiPhoneTransfer } from "react-icons/pi";
import { MdAddIcCall } from "react-icons/md";
import { MdDialpad } from "react-icons/md";
import { BsMicMuteFill } from "react-icons/bs";
import { MdPhonePaused } from "react-icons/md";
import { IoMdMore } from "react-icons/io";
import { ImPhoneHangUp } from "react-icons/im";
import { IoCall } from "react-icons/io5";
import { MdBackspace } from "react-icons/md";

interface CallOptionsProps {
  callState: string;
  addCall: boolean;
  transferCall: boolean;
  showMoreOptions: boolean;
  dialKeys: boolean;
  mute: boolean;
  isFirstRecipient: boolean;
  isClientConnected: boolean;
  isTransferred: boolean;
  isConference: boolean;
  isFirstCall: boolean;
  callType: boolean;
  isIncomingCall: boolean
  callInitiatedFrom: boolean;
  handleAddCall: () => void;
  handleAddParticipantClick: () => void;
  handleClickTransfer: () => void;
  handleCallTransfer: () => void;
  handleDialKeysToggle: () => void;
  handleMuteClick: () => void;
  handleHoldClick: () => void;
  handleShowMoreOptionsToggle: () => void;
  handleHangUpClick: () => void;
  handleDialClick: () => void;
  handleBackspaceClick: () => void;
}

const CallOptions: React.FC<CallOptionsProps> = ({
  callState,
  addCall,
  transferCall,
  showMoreOptions,
  dialKeys,
  isTransferred,
  isConference,
  isClientConnected,
  isFirstRecipient,
  isFirstCall,
  callType,
  isIncomingCall,
  callInitiatedFrom,
  mute,
  handleMuteClick,
  handleCallTransfer,
  handleDialKeysToggle,
  handleAddCall,
  handleAddParticipantClick,
  handleClickTransfer,
  handleHoldClick,
  handleHangUpClick,
  handleShowMoreOptionsToggle,
  handleDialClick,
  handleBackspaceClick,
}: CallOptionsProps): ReactElement => {
  // Create a boolean for the condition to simplify usage
  const isCallActiveOrHeld: boolean =
    callState === "active" || callState === "held";

  return (
    <div className="flex flex-col">
      <div className="relative">
        <div
          className={`flex justify-end absolute transition-all ease-in-out duration-300 w-full ${!showMoreOptions ? "top-0 -z-10 " : "-top-[115px]"
            }`}
        >
          {isCallActiveOrHeld &&
            !isTransferred &&
            !isConference &&
            isFirstRecipient
            && !isIncomingCall && (
              <div className="w-1/4">
                <div className="flex flex-col items-center">
                  <div
                    className="cursor-pointer my-2 mx-auto w-[50px] h-[50px] text-2xl flex items-center justify-center rounded-full"
                    onClick={
                      addCall
                        ? handleAddCall
                        : isConference
                          ? () => { }
                          : handleAddParticipantClick
                    }
                  >
                    <MdAddIcCall />
                  </div>
                  <div className="text-sm">{"Add Call"}</div>
                </div>
              </div>
            )}
          {isCallActiveOrHeld && !isTransferred && !isConference && (callType || callInitiatedFrom) && !isIncomingCall && (
            <div className="w-1/4">
              <div className="flex flex-col items-center">
                <div
                  className="cursor-pointer my-2 mx-auto w-[50px] h-[50px] text-2xl flex items-center justify-center rounded-full"
                  onClick={
                    transferCall
                      ? handleClickTransfer
                      : isTransferred
                        ? (): void => { }
                        : handleCallTransfer
                  }
                >
                  <PiPhoneTransfer />
                </div>
                <div className="text-sm">{"Transfer"}</div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className={`flex justify-around bg-white transition-all ease-in-out duration-300 z-10`}
      >
        {isCallActiveOrHeld && (
          <>
            <div className="w-1/4">
              <div className="flex flex-col items-center">
                <div
                  className={`cursor-pointer my-2 mx-auto w-[50px] h-[50px] text-2xl flex items-center ${dialKeys ? "bg-green-400 text-white" : "bg-white text-black"
                    } justify-center rounded-full`}
                  onClick={handleDialKeysToggle}
                >
                  <MdDialpad />
                </div>
                <div className="text-sm">{"Dial"}</div>
              </div>
            </div>
            <div className="w-1/4">
              {isCallActiveOrHeld && (
                <div className="flex flex-col items-center">
                  <div
                    className={`cursor-pointer my-2 mx-auto w-[50px] h-[50px] text-2xl flex items-center ${mute ? "bg-green-400 text-white" : "bg-white text-black"
                      } justify-center rounded-full`}
                    onClick={callState === "held" ? () => { } : handleMuteClick}
                  >
                    <BsMicMuteFill />
                  </div>
                  <div className="text-sm">{"Mute"}</div>
                </div>
              )}
            </div>

            <div className="w-1/4">
              {isCallActiveOrHeld && (
                <div className="flex flex-col items-center">
                  <div
                    className={`flex justify-center ${callState === "held"
                      ? "bg-green-400 text-white"
                      : "bg-white text-black"
                      } rounded-full items-center my-2 mx-auto w-[50px] h-[50px] text-2xl`}
                    onClick={handleHoldClick}
                  >
                    <MdPhonePaused />
                  </div>
                  <div className="text-sm">{"Hold"}</div>
                </div>
              )}
            </div>

            <div className="w-1/4">
              {isCallActiveOrHeld && (
                <div className="flex flex-col items-center">
                  <div
                    className={`cursor-pointer my-2 mx-auto w-[50px] h-[50px] text-2xl flex items-center ${showMoreOptions
                      ? "bg-green-400 text-white"
                      : "bg-white text-black"
                      } justify-center rounded-full`}
                    onClick={handleShowMoreOptionsToggle}
                  >
                    <IoMdMore />
                  </div>
                  <div className="text-sm">{"More"}</div>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div className="flex justify-end bg-white z-10">
        <div className="w-1/3">
          {isCallActiveOrHeld ? (
            <div
              className="cursor-pointer my-2 mx-auto w-[50px] h-[50px] text-2xl flex items-center bg-red-500 justify-center rounded-full"
              onClick={handleHangUpClick}
            >
              <ImPhoneHangUp className="text-white" />
            </div>
          ) : (
            <div
              className={`cursor-pointer my-2 mx-auto w-[50px] h-[50px] text-2xl flex items-center ${isClientConnected ? "bg-green-500" : "bg-slate-400"
                } justify-center rounded-full`}
              onClick={
                isClientConnected && isFirstCall ? handleDialClick : () => { }
              }
            >
              <IoCall className="text-white" />
            </div>
          )}
        </div>
        <div className="w-1/3">
          <div
            className="flex justify-center items-center my-2 mx-auto w-[50px] h-[50px] text-2xl cursor-pointer"
            onClick={handleBackspaceClick}
          >
            <MdBackspace />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallOptions;
