// components/ConfirmationModal.tsx

import React from "react";
import ButtonComponent from "components/ButtonComponent";
import ModalComponent from "components/ModalComponent";

interface ConfirmationModalProps {
  label: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  message,
  onConfirm,
  onCancel,
  setIsModal,
  label,
}: ConfirmationModalProps): JSX.Element => {
  return (
    <ModalComponent setIsModal={setIsModal} label={label}>
      <>
        <div className="p-4">
          <p className=" text-center italic text-[15px]">{message}</p>
        </div>
        <div className="flex items-center justify-around gap-2 border-t border-solid border-gray-300 px-4 py-2">
          <ButtonComponent
            label="Cancel"
            customClass="bg-white text-grey border-gray-400 text-gray-700 w-auto px-2 text-[15px] italic"
            handleClick={(): void => setIsModal(false)}
          />
          <ButtonComponent
            label="Confirm"
            customClass="bg-red-500 text-white border-red-500 w-auto px-2 text-[15px] italic"
            handleClick={(): void => {
              onConfirm();
              setIsModal(false);
            }}
          />
        </div>
      </>
    </ModalComponent>
  );
};

export default ConfirmationModal;
