import { UserDetails } from "types/call/callType";

export const getNurseIds = (nurseArray: UserDetails) => {
    return nurseArray.map(nurse => nurse.nurse_id);
};

interface NurseUser {
    nurse_id: string;
    username: string;
}

type DataItem = {
    nurse_id: string;
    name: string;
};

type MergedDataItem = {
    nurse_id: string;
    name: string;
    username: string;
};

export const mergeNurseData = (
    nurseData: NurseUser[],
    data: DataItem[]
): MergedDataItem[] => {
    // Create a map for nurse data
    const nurseMap = nurseData.reduce((acc: Record<string, string>, nurse: NurseUser) => {
        acc[nurse.nurse_id] = nurse.username;
        return acc;
    }, {});

    // Merge the data
    return data
        .map(d => {
            const username = nurseMap[d.nurse_id];
            if (username) {
                return {
                    nurse_id: d.nurse_id,
                    name: d.name,
                    username
                };
            }
            return null;
        })
        .filter((item): item is MergedDataItem => item !== null);
};