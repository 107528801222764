import { useState, useEffect, useRef } from 'react';
import TextComponent from '../../components/TextComponent';
import { NurseDetail } from 'types/call/callType';

const ComboBox = ({
    data,
    placeholder,
    callState,
    callDuration,
    sipName,
    setSipName,
    setSipDestinationNumber,
}: {
    data: NurseDetail[] | [];
    placeholder: string;
    callState: string;
    callDuration: number;
    sipName: string;
    setSipName: (value: string) => void;
    setSipDestinationNumber: (value: string) => void;
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const comboBoxRef = useRef<HTMLDivElement>(null);

    const filteredOptions = sipName
        ? data.filter((option) =>
              option.name.toLowerCase().includes(sipName.toLowerCase())
          )
        : data;

    const findTelnyxUsername = (username: string, users: NurseDetail[]) => {
        const user = users.find((user) => user.name === username);
        return user ? user.username : null;
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSipName(value);
        setSipDestinationNumber(findTelnyxUsername(value, data) ?? "");
        setIsDropdownOpen(true);
        setHighlightedIndex(-1);
    };

    const handleOptionClick = (option: NurseDetail) => {
        setSipName(option.name);
        setSipDestinationNumber(option.username);
        setIsDropdownOpen(false);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'ArrowDown') {
            setHighlightedIndex((prevIndex) =>
                prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0
            );
        } else if (event.key === 'ArrowUp') {
            setHighlightedIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1
            );
        } else if (event.key === 'Enter' && highlightedIndex >= 0) {
            handleOptionClick(filteredOptions[highlightedIndex]);
        }
    };

    const handleFocus = () => {
        setIsDropdownOpen(true);
        if (!sipName) {
            setHighlightedIndex(-1);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (comboBoxRef.current && !comboBoxRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={comboBoxRef}>
            {(callState === 'active' || callState === 'held') && (
                <div className="text-center mb-2">
                    Call Duration: {Math.floor(callDuration / 60)}:
                    {(callDuration % 60).toString().padStart(2, '0')}
                </div>
            )}
            <TextComponent
                type="text"
                value={sipName}
                handleChange={handleInputChange}
                handleFocus={handleFocus}
                handleKeyDown={handleKeyDown}
                placeholder={placeholder}
                className="border p-1 h-12 rounded w-full text-lg"
            />

            {isDropdownOpen && filteredOptions.length > 0 && (
                <ul className="border-[1px] p-0 m-0 absolute bg-white z-10 max-h-[150px] overflow-y-auto w-full">
                    {filteredOptions.map((option, index) => (
                        <li
                            key={option.name}
                            onClick={() => handleOptionClick(option)}
                            className={`p-[8px] cursor-pointer ${
                                index === highlightedIndex ? 'bg-[#f0f0f0]' : ''
                            } hover:bg-[#e6e6e6] `}
                        >
                            {option.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ComboBox;
