import { API_BASE_URL } from "utils/constant";
import { postHandler } from "helper/apiHandler";
import { NurseLoginField } from "types/auth/auth";

export async function call_control_dial(webRtcTo: string, dialTo: string) {
  const url: string = "/call-dial";

  const data = {
    to: webRtcTo,
    to2: dialTo,
  };

  const res = await postHandler(url, data);

  return res;
}

export async function call_control_create_conf(
  CCID1: string,
  CCID2: string,
  to: string
) {
  const url: string = "/create-conference";

  const data = {
    CCID1,
    CCID2,
    to,
  };

  const res = await postHandler(url, data);

  return res;
}

export async function call_transfer(CCID2: string, to: string) {
  const url: string = API_BASE_URL + "/call-transfer";

  const data = {
    CCID2,
    to,
  };

  const res = await postHandler(url, data);

  return res;
}

export async function twilio_add_participent(
  participantNumber: string,
  conferenceName: string
) {
  const url: string = API_BASE_URL + "/add-participant";

  const data = {
    participantNumber,
    conferenceName,
  };

  const res = await postHandler(url, data);

  return res;
}

export async function get_twilio_token() {
  const identity: string = btoa(process.env.REACT_APP_REFERENCE_ID as string);

  const url: string =
    API_BASE_URL +
    "/get-twilio-access-token?idendity=" +
    encodeURIComponent(identity);

  const reference_id: string = btoa(
    process.env.REACT_APP_REFERENCE_ID as string
  );

  const data = {
    reference_id,
  };

  const res = await postHandler(url, data);

  return res;
}

export async function twilio_call_transfer(
  participantNumber: string,
  conferenceName: string,
  callSid: string
) {
  const url: string = API_BASE_URL + "/call-transfer-twilio";

  const data = {
    participantNumber,
    conferenceName,
    callSid,
  };

  const res = await postHandler(url, data);

  return res;
}

export async function twilio_call_hold(conferenceName: string, hold: boolean) {
  const url: string = API_BASE_URL + "/call-hold-twilio";

  const data = {
    conferenceName,
    hold: hold,
  };

  const res = await postHandler(url, data);

  return res;
}

export async function get_creds(data: NurseLoginField) {
  const url: string = API_BASE_URL + "/user-info";

  const res = await postHandler(url, data);

  return res;
}

// To fetch The Nurse SIP Details
export async function get_user_details(nurseList: string[]) {
  const url: string = API_BASE_URL + "/nurse-details";

  const data = {
    referenceId: process.env.REACT_APP_REFERENCE_ID,
    nurseList
  }

  const res = await postHandler(url, data);

  return res;
}
