const decryptData = (encrypted: string) => {
  const password = process.env.REACT_APP_DECRYPTION_KEY as string;
  const pwdIterable: string =
    encrypted.length > password.length ? cycle(password) : password;
  let result: string = "";
  for (let i: number = 0; i < encrypted.length; i++) {
    result += String.fromCharCode(
      encrypted.charCodeAt(i) ^ pwdIterable.charCodeAt(i % pwdIterable.length)
    );
  }
  return result;
};

const cycle = (password: string) => {
  return password.repeat(Math.ceil(1000 / password.length)).slice(0, 1000);
};

export const decodeData = (base64Encrypted: string) => {
  const encrypted:string = atob(base64Encrypted); // Decode base64
  const decrypted = JSON.parse(decryptData(encrypted)); // Decrypt using XOR logic
  return decrypted;
};
