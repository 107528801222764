import {
  toast as toastNotify,
  ToastContent,
  ToastOptions,
} from "react-toastify";

export const toastOptions: ToastOptions = {
  autoClose: 3000,
  position: "top-center",
  className: "mt-2 text-md text-center",
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  hideProgressBar: true,
  closeButton: false,
  closeOnClick: true,
  style: {
    color: "orangered",
    fontSize: "14px",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "300px",
  },
};

export const toast = (content: ToastContent, options?: ToastOptions) =>
  toastNotify(content, { ...toastOptions, ...options });
