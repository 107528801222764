import { useEffect } from "react";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

const useWebSocket = (
  channelName: string,
  eventName: string,
  eventHandler: Function
) => {
  useEffect(() => {
    new Pusher(process.env.REACT_APP_SOCKET_KEY as string, {
      cluster: "mt1",
    });

    const echo = new Echo({
      broadcaster: process.env.REACT_APP_SOCKET_BROADCASTER,
      key: process.env.REACT_APP_SOCKET_KEY,
      wsHost: process.env.REACT_APP_SOCKET_HOST,
      wsPort: process.env.REACT_APP_SOCKET_PORT,
      forceTLS: true,
      encrypted: true,
      disableStats: true,
      debug: true,
      enabledTransports: ["ws", "wss"],
    });

    // Subscribe to channel and listen for events
    echo.channel(channelName).listen(eventName, eventHandler);

    // Cleanup on component unmount
    return () => {
      echo.disconnect()
      echo.leaveChannel(channelName);
    };
  }, [channelName, eventName, eventHandler]);
};

export default useWebSocket;
