import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler } from "react";

export default function TextComponent({
    label,
    type,
    handleChange,
    handleBlur,
    handleFocus,
    handleKeyDown,
    placeholder = "",
    isDisabled = false,
    value = undefined,
    className = "",
    isLabeled = true
}: {
    label?: string;
    type: string;
    handleChange?:
    | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
    handleBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    handleFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>; // Properly typed
    handleKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>; // Properly typed
    placeholder?: string;
    isDisabled?: boolean;
    value?: number | string | readonly string[] | undefined;
    className?: string,
    isLabeled?: boolean
}): JSX.Element {

    return (
        <div>
            {isLabeled && <label>{label}</label>}
            <input
                type={type}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                disabled={isDisabled}
                className={className}
            />
        </div>
    );
}