import { useCallback, useEffect, useState } from "react";
import { Audio } from "@telnyx/react-client";
import DialTone from "assets/audio/audio.mp3";
import IncomingTone from "assets/audio/incomingCall.mp3";
import DialPadKeys from "pages/Phone/DialPadKeys";
import CallOptions from "pages/Phone/CallOptions";
import DialInput from "pages/Phone/DialInput";
import FadeLoader from "react-spinners/FadeLoader";
import { TelnyxRTC } from "@telnyx/webrtc";
import {
  call_control_create_conf,
  call_control_dial,
  call_transfer,
} from "services/callService";
import {
  APIResponse,
  TelnyxCall,
  TelnyxNotification,
} from "types/call/callType";
import useWebSocket from "hooks/useEcho";
import ConfirmationModal from "components/ConfirmationModal";
import { toast } from "utils/toast";
import ComboBox from "pages/Phone/ComboBox";
import CallToggle from "./CallToggle";
import IncomingNotification from "pages/Phone/IncomingNotification";


var isErrorOnCall: boolean = false;
var isIncomingCall: boolean = false;
var isOutgoingCall: boolean = false;

const Dialpad = ({
  username,
  password,
  phoneNumber = "",
  userDetails = []
}: {
  username: string;
  password: string;
  phoneNumber?: string;
  userDetails: { nurse_id: string, name: string, username: string }[] | []

}) => {
  //===================== States ======================================//
  const [destinationNumber, setDestinationNumber] = useState(phoneNumber);
  const [sipDestinationNumber, setSipDestinationNumber] = useState("");
  const [sipName, setSipName] = useState("")
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [isClientConnected, setIsClientConnected] = useState(false);
  const [isFirstRecipient, setIsFirstRecipient] = useState(true);
  const [addCall, setAddCall] = useState(true);
  const [transferCall, setTransferCall] = useState(true);
  const [mute, setMute] = useState(false);
  const [dialKeys, setDialKeys] = useState(true);
  const [timer, setTimer] = useState<number | NodeJS.Timeout | null>(null);
  const [callDuration, setCallDuration] = useState(0);
  const [isCallAnswered, setIsCallAnswered] = useState(false); //socket

  const [isFirstCall, setIsFirstCall] = useState(true);
  const [isTransferred, setIsTransferred] = useState(false);
  const [isConference, setIsConference] = useState(false);

  const [CCID1, setCCID1] = useState("");
  const [CCID2, setCCID2] = useState("");

  const [call, setCall] = useState<TelnyxCall | null>(null);
  const [callState, setCallState] = useState("");

  const [isModal, setIsModal] = useState(false);
  const [incomingNumber, setIncomingNumber] = useState("");
  const [callType, setCallType] = useState(true);
  const [callInitiatedFrom, setCallInitiatedFrom] = useState(true);

  const handleEvent = () => {
    setIsCallAnswered(true);
  };

  useWebSocket(
    `call-answered${process.env.REACT_APP_REFERENCE_ID}`,
    "CallAnsweredEvent",
    handleEvent
  );
  //===================== variables ======================================//

  const incomingAudio = new window.Audio(IncomingTone);

  useEffect(() => {
    const initializeTelnyxClient = async () => {
      try {
        if (username && password) {
          const telnyxClient = new TelnyxRTC({
            login: username,
            password: password,
          });

          telnyxClient.connect();
          console.log(telnyxClient.connected);

          telnyxClient
            .on("telnyx.ready", () => {
              setIsClientConnected(true);
            })
            .on("telnyx.notification", (notification: TelnyxNotification) => {
              // console.log("notification", notification);
              // console.log("isErrorOnCall", isErrorOnCall);
              // console.log("isIncomingCall", isIncomingCall);
              // console.log("isOutgoingCall", isOutgoingCall);

              if (notification.type === "callUpdate") {
                if (callState !== "active") {
                  const updatedCall: TelnyxCall = notification.call;
                  setIncomingNumber(updatedCall.options.remoteCallerNumber);

                  // console.log("updatedCall.state =>>>>>>", updatedCall.state);
                  // console.log("isErrorOnCall  =>>>>>>", isErrorOnCall);
                  // console.log("isIncomingCall =>>>>>>", isIncomingCall);
                  // console.log("isOutgoingCall =>>>>>>", isOutgoingCall);

                  // console.log(
                  //   "updatedCall.options.callerName",
                  //   updatedCall.options.callerName
                  // );

                  if (
                    updatedCall.state === "ringing" &&
                    !isIncomingCall &&
                    isOutgoingCall &&
                    updatedCall.options.callerName === "Outbound Call"
                  ) {
                    updatedCall.answer();
                    isIncomingCall = false;
                    isOutgoingCall = true;

                    // console.log("callllllllllllllllll Anssssssssssssssssssssssssssssssssssswered");
                  }

                  if (!isErrorOnCall) {
                    setCall(updatedCall);
                    setCallState(updatedCall.state);
                  }

                  if (updatedCall.state === "hangup") {
                    // console.log("HANG Uppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppp");
                    setToDefaultState();
                  }
                }
              }
            })
          // .on('telnyx.error', (error:any) => {
          //   console.log('Error connecting to Telnyx:=====================================================', error);
          // })
          // .on('telnyx.socket.close', () => {
          //   console.log('Telnyx WebSocket connection closed.===================================================');
          // });
        }
      } catch (error) {
        toast("Error initializing Telnyx client");
      }
    };

    initializeTelnyxClient();
  }, [username, password, callState]);

  useEffect(() => {
    if (call?.state === "ringing" && call.options.callerName === "Outbound Call" && !isOutgoingCall && !isIncomingCall) {
      incomingAudio.play();
    }
  }, [call?.state]);

  useEffect(() => {
    const cleanup = () => {
      if (timer) {
        clearInterval(timer);
      }
    };

    if (callState === "active" && isCallAnswered) {
      const callTimer = setInterval(
        () => setCallDuration((prev: number): number => prev + 1),
        1000
      );
      setTimer(callTimer);
      setDialKeys(false);
    }

    if (callState === "destroy") {
      setDialKeys(true);
    }

    return () => cleanup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callState, isCallAnswered]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (
        (e.key === "r" && e.ctrlKey && !e.shiftKey) || // Ctrl + R
        (e.key === "r" && e.ctrlKey && e.shiftKey) || // Ctrl + Shift + R
        e.key === "F5" // F5
      ) {
        e.preventDefault(); // Prevent the default reload behavior
        if (
          callState === "active" ||
          callState === "held" ||
          callState === "ringing"
        ) {
          setIsModal(true);
        } else {
          window.location.reload();
        }
      }
    };

    // Add the event listener
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [callState]);
  //====================== Functions ==============================//

  const handleAnswerCall = () => {
    isIncomingCall = true;
    isOutgoingCall = false;
    incomingAudio.pause();
    call?.answer();
  };

  const handleRejectCall = () => {
    isIncomingCall = false;
    isOutgoingCall = false;
    incomingAudio.pause();
    call?.hangup();
  };

  const isValidPhoneNumber = (phoneNumber: string, callType: boolean) => {
    let cleanedNumber = phoneNumber.replace(/\s/g, "") as string;
    console.log(cleanedNumber, "cleanedNumber");


    const phoneRegex = /^\d{10}$/;
    const sipRegex = /^sip:[\w._-]+@sip\.telnyx\.com$/
      ;

    if (callType) {
      if (phoneRegex.test(cleanedNumber)) {
        cleanedNumber = "+1" + cleanedNumber;
        return { isValid: true, cleanedNumber };
      }
      return { isValid: false, cleanedNumber };
    } else {
      if (sipRegex.test(cleanedNumber)) {
        return { isValid: true, cleanedNumber };
      }
      return { isValid: false, cleanedNumber };
    }
  };


  const firstCall = async (webRTCClient: string, to: string) => {
    try {
      if (!to) {
        toast("Please enter a destination number.");
        return;
      }

      // console.log(to, "<<<<<<<<<<<");

      // console.log(isValidPhoneNumber(to, callType), "asdhvshvajudf");


      const { isValid, cleanedNumber } = isValidPhoneNumber(to, callType);
      // console.log(callType, cleanedNumber, ">>>>>>>>>>>>>>>>>>>>>>>>");


      if (!isValid) {
        toast("Please enter a valid destination number.");
        return;
      }

      // isErrorOnCall = false;   FROM IN MERGE
      isOutgoingCall = true;

      setIsFirstCall(false);
      setCallDuration(0);

      const webRTCCall: APIResponse = await call_control_dial(
        webRTCClient,
        cleanedNumber as string
      );

      if (!webRTCCall.success) {
        toast(webRTCCall.message || "Failed to make the call.");
        setIsFirstCall(true);
        setCall(null);
        setCallState("");
        isErrorOnCall = true;
        isOutgoingCall = false;
        return;
      }

      window.opener.postMessage({ action: "callStarted" }, "*");

      const { CCID1, CCID2 } = webRTCCall.data;
      setCCID1(CCID1);
      setCCID2(CCID2);
    } catch (err) {
      setIsFirstCall(true);
      toast("Error in making the call.");
    }
  };

  const handleDigitClick = useCallback(
    (value: string) => () => {
      const audio = new window.Audio(DialTone);
      audio.play();

      setDestinationNumber((destinationNumber: string): string =>
        destinationNumber.concat(value)
      );
    },
    []
  );

  const handleDialClick = () => {
    const webClient = `sip:${username}@sip.telnyx.com`;
    setCallInitiatedFrom(callType);             //Set Call Initiated From To Current Tab (It Will Be Change After Tab Change But It Doesnt Matter)
    firstCall(webClient, callType ? destinationNumber : `sip:${sipDestinationNumber}@sip.telnyx.com`);
  };

  const setToDefaultState = async () => {
    setDestinationNumber("");
    setShowMoreOptions(false);
    setIsFirstRecipient(true);
    setAddCall(true);
    setTransferCall(true);
    setMute(false);
    setDialKeys(true);
    setIsTransferred(false);
    setIsConference(false);
    setCCID1("");
    setCCID2("");
    setIsFirstCall(true);
    setIsCallAnswered(false);
    setCallDuration(0);
    setSipName("");
    setSipDestinationNumber("");
    setCallInitiatedFrom(true);
    isIncomingCall = false;
    isOutgoingCall = false;
    isErrorOnCall = false;
    window.opener.postMessage({ action: "callEnded" }, "*");
  };

  const handleHangUpClick = () => {
    call?.hangup();
    setToDefaultState();
  };

  const handleHoldClick = async () => {
    try {
      await call?.toggleHold();
    } catch (error) {
      toast("Error holding the call.");
    }
  };

  const handleMuteClick = async () => {
    call?.toggleAudioMute();
    setMute((prev: boolean): boolean => !prev);
  };

  const handleDialKeysToggle = async () => {
    setDialKeys((prev: boolean): boolean => !prev);
    setShowMoreOptions(false);
  };

  const handleShowMoreOptionsToggle = async () => {
    setShowMoreOptions((prev: boolean): boolean => !prev);
  };

  const handleBackspaceClick = () => {
    // Explicitly use the browser's Audio constructor via the window object
    const audio = new window.Audio(DialTone);
    audio.play();

    if (callType) {

      setDestinationNumber((destinationNumber: string): string =>
        destinationNumber.slice(0, -1)
      );
    }
    else {
      setSipName((sipName: string): string =>
        sipName.slice(0, -1)
      );
    }
  };

  const handleAddCall = () => {
    setDialKeys(true);
    setDestinationNumber("");
    setSipName("")
    setAddCall(false);
  };

  const handleClickTransfer = () => {
    setDialKeys(true);
    setDestinationNumber("");
    setSipName("")
    setTransferCall(false);
  };

  const handleAddParticipantClick = async () => {
    try {
      // Validate destination number input
      if (callType ? !destinationNumber : !sipDestinationNumber) {
        toast(`${callType ? "Please enter a destination number." : "Please enter a sip id."}`);
        return;
      }

      const { isValid, cleanedNumber } =
        isValidPhoneNumber(callType ? destinationNumber : `sip:${sipDestinationNumber}@sip.telnyx.com`, callType);

      if (!isValid) {
        toast(`${callType ? "Please enter a destination number." : "Please enter a sip id."}`);
        return;
      }

      // Create conference call
      setIsConference(true);
      const createConference: APIResponse = await call_control_create_conf(
        CCID1,
        CCID2,
        cleanedNumber as string
      );

      if (!createConference.success) {
        toast(createConference.message || "Failed to create conference.");
        setIsConference(false);
        return;
      }

      // Update state after successful conference creation
      setDialKeys(false);
      setShowMoreOptions(false);
      setIsFirstRecipient(false);
    } catch (error) {
      toast("Error adding participant.");
    }
  };

  const handleCallTransfer = async () => {
    try {
      // Validate destination number input
      if (callType ? !destinationNumber : !sipDestinationNumber) {
        toast(`${callType ? "Please enter a destination number." : "Please enter a sip id."}`);
        return;
      }

      const { isValid, cleanedNumber } =
        isValidPhoneNumber(callType ? destinationNumber : `sip:${sipDestinationNumber}@sip.telnyx.com`, callType);

      if (!isValid) {
        toast(`${callType ? "Please enter a destination number." : "Please enter a sip id."}`);
        return;
      }

      // Set transfer state before making the API call
      setIsTransferred(true);

      // Initiate call transfer
      const transferCall: APIResponse = await call_transfer(
        CCID2,
        cleanedNumber as string
      );

      if (!transferCall.success) {
        toast(transferCall.message || "Error transferring the call.");
        setIsTransferred(false); // Reset state if the transfer fails
        return;
      }

      toast("Call Transferred Successfully", {
        style: {
          color: "green",
          fontSize: "14px",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          maxWidth: "300px",
        },
      });

      setToDefaultState();
    } catch (err) {
      toast("Error In Transferring Call");
    }
  };

  // ========================= component flow ==============================//

  if (!isClientConnected)
    return <FadeLoader color="#3498db" className="mx-auto" />;

  return (
    <>
      <div
        className={`relative transition-all ease-in-out duration-300 ${callState === "ringing" && !isIncomingCall && !isOutgoingCall
          ? "-top-3"
          : "-top-full"
          }`}
      >
        <IncomingNotification
          handleAnswerCall={handleAnswerCall}
          handleRejectCall={handleRejectCall}
          incomingNumber={incomingNumber}
        />
      </div>
      <div
        className={`flex flex-col justify-between w-[280px] mx-auto gap-1.5 h-full overflow-hidden`}
      >
        <div className="relative flex flex-col gap-4 w-full">
          {/* <CallToggle selectedOption={callType} setSelectedOption={setCallType} />  for sip call  */} 

          {callType ? <DialInput
            callState={callState}
            callDuration={callDuration}
            destinationNumber={destinationNumber}
            setDestinationNumber={setDestinationNumber}

          />
            : <ComboBox
              data={userDetails}
              placeholder="Select the SIP"
              callState={callState}
              callDuration={callDuration}
              sipName={sipName}
              setSipName={setSipName}
              setSipDestinationNumber={setSipDestinationNumber}
            />}
        </div>

        {/* Dial Keys */}
        <div
          className={` transition-all relative ease-in-out duration-300 ${dialKeys && !showMoreOptions ? " top-0" : " top-full -z-10"
            }`}
        >
          {callType && <DialPadKeys handleDigitClick={handleDigitClick} />}
        </div>
        {/* call Options */}
        <CallOptions
          callState={callState}
          addCall={addCall}
          transferCall={transferCall}
          showMoreOptions={showMoreOptions}
          dialKeys={dialKeys}
          isClientConnected={isClientConnected}
          mute={mute}
          isTransferred={isTransferred}
          isConference={isConference}
          isFirstRecipient={isFirstRecipient}
          isFirstCall={isFirstCall}
          callType={callType}
          isIncomingCall={isIncomingCall}
          callInitiatedFrom={callInitiatedFrom}
          handleAddCall={handleAddCall}
          handleDialKeysToggle={handleDialKeysToggle}
          handleAddParticipantClick={handleAddParticipantClick}
          handleClickTransfer={handleClickTransfer}
          handleMuteClick={handleMuteClick}
          handleCallTransfer={handleCallTransfer}
          handleHoldClick={handleHoldClick}
          handleHangUpClick={handleHangUpClick}
          handleShowMoreOptionsToggle={handleShowMoreOptionsToggle}
          handleDialClick={handleDialClick}
          handleBackspaceClick={handleBackspaceClick}
        />
        <Audio stream={call?.remoteStream} />

        {isModal && (
          <ConfirmationModal
            label=""
            message="Are you sure you want to reload the page? Your call will be disconnected."
            onConfirm={() => window.location.reload()}
            onCancel={() => setIsModal(false)}
            setIsModal={setIsModal}
          />
        )}
      </div>
    </>
  );
};

export default Dialpad;
