import NurseConnection from "pages/Phone/NurseConnection";

const PhonePage = () => {
  return (
    <div className="p-5 h-screen">
      <div className="flex flex-col h-full">
        <NurseConnection />
      </div>
    </div>
  );
};

export default PhonePage;
