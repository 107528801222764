import { Dispatch, SetStateAction } from "react";
import { IoMdClose } from "react-icons/io";

export default function ModalComponent({
  label,
  children,
  setIsModal,
}: {
  label: string;
  children: JSX.Element;
  setIsModal: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="relative p-4 max-w-sm w-full">
        <div className="bg-slate-100 rounded-lg shadow">
          <div className="flex justify-end">
            <div className="w-full py-2 px-4 flex justify-between items-center ">
              <p className="">{label}</p>
              <div
                onClick={(): void => setIsModal(false)}
                className="relative cursor-pointer"
              >
                <IoMdClose />
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
