import axios, { AxiosInstance } from "axios";
import { API_BASE_URL } from "utils/constant";
import { toast } from "utils/toast";

type ErrorType = {
  code: string;
  response: {
    data: {
      message: string;
    };
  };
};

const createAxiosInstance = (): AxiosInstance => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      "nurse-id": `${btoa(sessionStorage.getItem("nurseId") as string)}`,
      "ngrok-skip-browser-warning": "true",
    },
  });
};



const handleAxiosError = (error: ErrorType) => {
  if (error.code === "ERR_NETWORK") {
    toast("Network Error");
    return { success: false };
  } else {
    const message: string = error.response?.data?.message as string;
    return { success: false, message };
  }
};

const handleGetError = (error: ErrorType) => {
  if (error.code === "ERR_NETWORK") {
    toast("Network Error");
    return { success: false };
  } else {
    const message: string = error.response?.data?.message as string;
    return { success: false, message };
  }
};

export const postHandler = async (url: string, body: object) => {
  try {
    const res = await createAxiosInstance().post(url, body);
    const success: boolean = res.data.status === "success";
    return { ...res.data, success };
  } catch (error) {
    return handleAxiosError(error as ErrorType);
  }
};

export const getHandler = async (url: string) => {
  try {
    const res = await createAxiosInstance().get(url);
    const success: boolean = res.data.status === "success";
    return { ...res.data, success };
  } catch (error) {
    return handleGetError(error as ErrorType);
  }
};

