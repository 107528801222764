import { NurseLoginField } from "types/auth/auth";
import { get_creds, get_user_details } from "services/callService";
import { useEffect, useState } from "react";
import TelnyxDialpad from "pages/Phone/TelnyxDialpad";
import TwilioDialpad from "pages/Phone/TwilioDialpad";
import { useLocation } from "react-router-dom";
import { toast } from "utils/toast";
import { decodeData } from "helper/encryptData";
import { CustomWindow, UserDetails } from "types/call/callType";
import { getNurseIds, mergeNurseData } from "helper/commonHelper";



const NurseConnection = () => {
  const [accessToken, setAccessToken] = useState("");
  const [service, setService] = useState("");
  const [telnyxUsername, setTelnyxUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, SetPhoneNumber] = useState("");
  const [userDetails, setUserDetails] = useState<{ nurse_id: string, name: string, username: string }[] | []>([])
  const location = useLocation();

  const fetchCreds = async (data: NurseLoginField) => {
    try {
      const response = await get_creds(data);
      if (!response.success) {
        toast(response.message || "Failed to retrieve Credentials.");
        return;
      }

      const creds: {
        telnyx_username: string;
        telnyx_password: string;
        service: string;
      } = decodeData(response.data);

      setService(creds.service);

      if (creds.service === "telnyx") {
        const { telnyx_password, telnyx_username } = creds;
        setTelnyxUsername(telnyx_username);
        setPassword(telnyx_password);
      }
    } catch (err) {
      toast("Failed to retrieve Credentials.");
    }
  };

  const fetchUserDetails = async (data: UserDetails) => {
    try {
      const response = await get_user_details(getNurseIds(data));
      if (!response.success) {
        toast(response.message || "Failed to retrieve User Details.");
        return;
      }

      const nurseData = decodeData(response.data);

      const mergedData = mergeNurseData(nurseData, data)
      setUserDetails(mergedData);
    } catch (err) {
      toast("Failed to retrieve User Details.");
    }
  };

  function excludeAndSortNurseDetails(userDetails: UserDetails, nurseIdToRemove: string) {
    const newArray = userDetails
      .filter((user) => user.nurse_id !== nurseIdToRemove)
      .sort((a, b) => parseInt(a.nurse_id) - parseInt(b.nurse_id));
    return newArray;
  }

  useEffect(() => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const encodedToken = queryParams.get("token");
      if (encodedToken) {
        const decodedData: CustomWindow = JSON.parse(atob(encodedToken));
        setAccessToken(decodedData.token);
        SetPhoneNumber(decodedData.phoneNumber);
        sessionStorage.setItem("token", decodedData.token);
        sessionStorage.setItem("nurseId", decodedData.nurseId);
        const data = {
          nurseId: decodedData.nurseId,
          service: decodedData.service,
          encounterId: decodedData.encounterId,
        };
        fetchCreds(data);

        const filteredUserDetails = excludeAndSortNurseDetails(decodedData.userDetails || [], decodedData.nurseId)
        fetchUserDetails(filteredUserDetails)
      }
    } catch (error) {
      toast("Token is invalid");
    }
  }, []);

  return (
    <>
      {service === "telnyx" && (
        <TelnyxDialpad
          username={telnyxUsername}
          password={password}
          phoneNumber={phoneNumber}
          userDetails={userDetails}
        />
      )}
      {service === "twilio" && <TwilioDialpad accessToken={accessToken} />}
    </>
  );
};

export default NurseConnection;
