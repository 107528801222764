import axios from "axios";

async function getData() {
  const url =
    (process.env.REACT_APP_BACKEND_URL as string) +
    "/api/v1/auth/generate-token";
  try {
    const response = await axios.post(
      url,
      {
        reference_id: btoa(process.env.REACT_APP_REFERENCE_ID as string),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": process.env.REACT_APP_BACKEND_API_KEY as string,
          "X-Authorization-Secret": process.env.REACT_APP_SECRET_KEY as string,
        },
      }
    );
    return response.data.data.token;
  } catch (error: any) {
    console.error(error.message);
  }
}

const HomePage = () => {
  const handleCallButtonClick = async () => {
    const receivedToken = await getData(); // Fetch the token on button click
    if (receivedToken) {
      if ((window as Window).openWindow) {
        (window as Window).openWindow({
          token: receivedToken,
          nurseId: "19",
          referenceId: "NA3tN75anKRkuBbGHbpTkX52oPw9YtQc",
          encounterId: Date.now().toString() + "1",
          service: "telnyx",
          phoneNumber: "8009256278",
          userDetails: [
            { "nurse_id": "20", "name": "OUT MERGE COPY" },
          ]
        }); // Use the token in openWindow
      } else {
        console.error("openWindow function is not available on window object");
      }
    } else {
      console.error("Failed to fetch token");
    }
  };
  const handleCallButtonClick2 = async () => {
    const receivedToken = await getData(); // Fetch the token on button click
    if (receivedToken) {
      if ((window as Window).openWindow2) {
        (window as Window).openWindow2({
          token: receivedToken,
          nurseId: "20",
          referenceId: "NA3tN75anKRkuBbGHbpTkX52oPw9YtQc",
          encounterId: Date.now().toString() + "2",
          service: "telnyx",
          phoneNumber: "8009256278",
          userDetails: [
            { "nurse_id": "19", "name": "OUT MERGE" },
          ]
        }); // Use the token in openWindow
      } else {
        console.error("openWindow function is not available on window object");
      }
    } else {
      console.error("Failed to fetch token");
    }
  };
  return (
    <div>
      <div className="m-2">
        <button
          className="cursor-pointer focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          onClick={handleCallButtonClick}
        >
          Call Us Now
        </button>
        <button
          className="cursor-pointer focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          onClick={handleCallButtonClick2}
        >
          Call Us Now
        </button>
      </div>
    </div>
  );
};

export default HomePage;
