import { Call, Device } from "@twilio/voice-sdk";
import { useCallback, useEffect, useState } from "react";
import DialTone from "assets/audio/audio.mp3";
import DialPadKeys from "pages/Phone/DialPadKeys";
import CallOptions from "pages/Phone/CallOptions";
import DialInput from "pages/Phone/DialInput";
import FadeLoader from "react-spinners/FadeLoader";
import {
  get_twilio_token,
  twilio_add_participent,
  twilio_call_hold,
  twilio_call_transfer,
} from "services/callService";
import { APIResponse } from "types/call/callType";
import { TwilioConnection } from "types/call/callType";
import { toast } from "utils/toast";

var isIncomingCall: boolean = false;

const Dialpad = ({ accessToken }: { accessToken: string }) => {

  const [token, setToken] = useState("");
  const [connection, setConnection] = useState<TwilioConnection | null>(null);
  const [device, setDevice] = useState<Device | null>(null);
  const [csid, setCsid] = useState("");
  const [conferenceName, setConferenceName] = useState<string>("");
  const [clientCall, setClientCall] = useState<Call | null>(null);

  //===================== States ======================================//
  const [destinationNumber, setDestinationNumber] = useState("");
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [isClientConnected, setIsClientConnected] = useState(false);
  const [isFirstRecipient, setIsFirstRecipient] = useState(true);
  const [addCall, setAddCall] = useState(true);
  const [transferCall, setTransferCall] = useState(true);
  const [mute, setMute] = useState(false);
  const [dialKeys, setDialKeys] = useState(true);
  const [timer, setTimer] = useState<number | NodeJS.Timeout | null>(null);
  const [callDuration, setCallDuration] = useState(0);
  const [isCallFinished, setIsCallFinished] = useState(false);
  const [closingTimer, setClosingTimer] = useState(3);

  const [isFirstCall, setIsFirstCall] = useState(true);
  const [isTransferred, setIsTransferred] = useState(false);
  const [isConference, setIsConference] = useState(false);

  const [callState, setCallState] = useState("");
  const [callType, setCallType] = useState(true);
  const [callInitiatedFrom, setCallInitiatedFrom] = useState(true);



  //===================== variables ======================================//

  useEffect(() => {
    if (accessToken) {
      getTwilioToken();
    }
  }, [accessToken]);

  useEffect(() => {
    if (token) {
      const device = new Device(token);
      setDevice(device);
      setCallState("ready");

      return () => {
        device.destroy();
      };
    }
  }, [token]);

  useEffect(() => {
    if (clientCall) {
      clientCall.on("disconnect", () => {
        handleHangUpClick();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientCall]);

  useEffect(() => {
    const cleanup = () => {
      if (timer) {
        clearInterval(timer);
      }
    };

    if (callState === "active") {
      cleanup();
      const callTimer = setInterval(
        () => setCallDuration((prev: number): number => prev + 1),
        1000
      );
      setTimer(callTimer);
      setDialKeys(false);
    } else if (callState === "destroy") {
      cleanup();
      setCallDuration(0);
      setDialKeys(true);

      const closingInterval: NodeJS.Timer = setInterval(
        () => setClosingTimer((prev: number): number => prev - 1),
        1000
      );
      setTimer(closingInterval);
      setToDefaultState();
    }

    return () => cleanup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callState]);

  //====================== Functions ==============================//

  const getTwilioToken = async () => {
    try {
      const tokenResponse: APIResponse = await get_twilio_token();

      if (!tokenResponse.success) {
        toast(
          tokenResponse.message || "Failed to retrieve Token."
        );
        setIsFirstCall(true);
        return;
      }

      setToken(tokenResponse.data.token);
      setIsClientConnected(true);
    } catch (error) {
      toast("Something Went Wrong!!!");
    }
  };

  const isValidPhoneNumber = (phoneNumber: string) => {
    const regex = /^\+?[1-9]\d{1,14}$/;
    return regex.test(phoneNumber);
  };

  const firstCall = async (to: string) => {
    try {
      if (!to) {
        toast("Please enter a destination number.");
        return;
      }

      if (!isValidPhoneNumber(to)) {
        toast("Please enter a valid destination number.");
        return;
      }

      setIsFirstCall(false);

      const confName = process.env.REACT_APP_REFERENCE_ID
        ? process.env.REACT_APP_REFERENCE_ID.slice(-5) + Date.now()
        : Date.now().toString();

      setConferenceName(confName);

      const res = await (device as Device).connect({
        params: {
          To: to,
          conferenceName: confName,
          reference_id: btoa(process.env.REACT_APP_REFERENCE_ID as string),
        },
      });
      setClientCall(res);

      res.on("accept", (con: TwilioConnection) => {
        setConnection(con);
      });

      const waitForCallSid = new Promise((resolve) => {
        const checkCallSid = setInterval(() => {
          if (res?.parameters?.CallSid) {
            clearInterval(checkCallSid);
            resolve(res.parameters.CallSid);
          }
        }, 100);
      });

      const callSid: string = (await waitForCallSid) as string;
      setCsid(callSid);

      const webRTCCall: APIResponse = await twilio_add_participent(
        destinationNumber,
        confName
      );

      if (!webRTCCall.success) {
        toast(webRTCCall.message || "Failed to make the call.");
        setIsFirstCall(true);
        return;
      }

      setCallState("active");
    } catch (err) {
      setIsFirstCall(true);
      toast("Error in making the call.");
    }
  };

  const handleDigitClick = useCallback(
    (value: string) => () => {
      const audio = new window.Audio(DialTone);
      audio.play();

      setDestinationNumber((destinationNumber: string): string =>
        destinationNumber.concat(value)
      );
    },
    []
  );

  const handleDialClick = () => {
    firstCall(destinationNumber);
  };

  const setToDefaultState = () => {
    setDestinationNumber("");
    setShowMoreOptions(false);
    setIsFirstRecipient(true);
    setAddCall(true);
    setTransferCall(true);
    setMute(false);
    setDialKeys(false);
    setIsCallFinished(true);
    setIsTransferred(false);
    setIsConference(false);
    setIsFirstCall(true);
    setToken("");
    setConnection(null);
    setDevice(null);
    setCallState("destroy");
    setCsid("");
    setConferenceName("");
    setClientCall(null);
    setTimeout(() => {
      window.close();
    }, 3000);
  };

  const handleHangUpClick = () => {
    (device as Device).disconnectAll();
    setToDefaultState();
  };

  const handleHoldClick = async () => {
    try {
      if (callState === "active") {
        const holdCall: APIResponse = await twilio_call_hold(
          conferenceName,
          true
        );

        if (!holdCall.success) {
          toast(
            holdCall.message || "Failed to put call on hold."
          );
          return;
        }

        setCallState("held");
      } else {
        const holdCall: APIResponse = await twilio_call_hold(
          conferenceName,
          false
        );

        if (!holdCall.success) {
          toast(
            holdCall.message || "Failed to put call on hold."
          );
          return;
        }

        setCallState("active");
      }
    } catch (error) {
      toast("Error holding the call.");
    }
  };

  const handleMuteClick = async () => {
    if (!connection) return;
    try {
      const newMuteState: boolean = !mute;
      await connection.mute(newMuteState);
      setMute(newMuteState);
    } catch (error) {
      toast("Failed to toggle mute");
    }
  };

  const handleDialKeysToggle = async () => {
    setDialKeys((prev: boolean): boolean => !prev);
  };

  const handleShowMoreOptionsToggle = async () => {
    setShowMoreOptions((prev: boolean): boolean => !prev);
  };

  const handleBackspaceClick = () => {
    const audio = new window.Audio(DialTone);
    audio.play();
    setDestinationNumber((destinationNumber: string): string =>
      destinationNumber.slice(0, -1)
    );
  };

  const handleAddCall = () => {
    setDialKeys(true);
    setDestinationNumber("");
    setAddCall(false);
  };

  const handleClickTransfer = () => {
    setDialKeys(true);
    setDestinationNumber("");
    setTransferCall(false);
  };

  const handleAddParticipantClick = async () => {
    try {
      // Validate destination number input
      if (!destinationNumber) {
        toast("Please enter a destination number.");
        return;
      }

      if (!isValidPhoneNumber(destinationNumber)) {
        toast("Please enter a valid destination number.");
        return;
      }
      setIsConference(true);

      const addParticipant: APIResponse = await twilio_add_participent(
        destinationNumber,
        conferenceName
      );

      if (!addParticipant.success) {
        toast(
          addParticipant.message || "Failed to add an another participent."
        );
        setIsConference(false);
        return;
      }

      // Update state after successful conference creation
      setDialKeys(false);
      setShowMoreOptions(false);
      setIsConference(true);
      setIsFirstRecipient(false);
    } catch (error) {
      toast("Error adding participant.");
    }
  };

  const handleCallTransfer = async () => {
    try {
      // Validate destination number input
      if (!destinationNumber) {
        toast("Please enter a destination number.");
        return;
      }

      if (!isValidPhoneNumber(destinationNumber)) {
        toast("Please enter a valid destination number.");
        return;
      }

      // Set transfer state before making the API call
      setIsTransferred(true);

      const transferCall: APIResponse = await twilio_call_transfer(
        destinationNumber,
        conferenceName,
        csid
      );

      if (!transferCall.success) {
        toast(transferCall.message || "Failed to Transfer Call.");
        setIsTransferred(false);
        return;
      }
    } catch (err) {
      toast("Error In Transfering Call");
    }
  };

  // ========================= component flow ==============================//

  if (!isClientConnected)
    return <FadeLoader color="#3498db" className="mx-auto" />;

  return (
    <div
      className={`flex flex-col justify-between max-w-[280px] mx-auto gap-5 ${
        !dialKeys && !isCallFinished ? "h-full" : ""
      }  `}
    >
      {!isCallFinished && (
        <DialInput
          callState={callState}
          callDuration={callDuration}
          destinationNumber={destinationNumber}
          setDestinationNumber={setDestinationNumber}
        />
      )}

      {!isCallFinished ? (
        dialKeys && <DialPadKeys handleDigitClick={handleDigitClick} />
      ) : (
        <div className="text-md">
          {`Your window will close in ${closingTimer} ${
            closingTimer > 1 ? "seconds." : "second."
          }`}
        </div>
      )}

      {!isCallFinished && (
        <CallOptions
          callState={callState}
          addCall={addCall}
          transferCall={transferCall}
          showMoreOptions={showMoreOptions}
          dialKeys={dialKeys}
          isClientConnected={isClientConnected}
          mute={mute}
          isTransferred={isTransferred}
          isConference={isConference}
          isFirstRecipient={isFirstRecipient}
          isFirstCall={isFirstCall}
          callType={callType}
          isIncomingCall={isIncomingCall}
          callInitiatedFrom={callInitiatedFrom}
          handleAddCall={handleAddCall}
          handleDialKeysToggle={handleDialKeysToggle}
          handleAddParticipantClick={handleAddParticipantClick}
          handleClickTransfer={handleClickTransfer}
          handleMuteClick={handleMuteClick}
          handleCallTransfer={handleCallTransfer}
          handleHoldClick={handleHoldClick}
          handleHangUpClick={handleHangUpClick}
          handleShowMoreOptionsToggle={handleShowMoreOptionsToggle}
          handleDialClick={handleDialClick}
          handleBackspaceClick={handleBackspaceClick}
        />
      )}
    </div>
  );
};

export default Dialpad;
